<div class="tx-card__wrapper {{ classes }} w-full mb-11" *ngIf="item">
  <a
    [routerLink]="[
      '/',
      activeLang,
      'artigos',
      item?.attributes.article_categories?.data[0]?.attributes?.slug,
      item?.attributes.slug + '-p' + item.id,
    ]"
  >
    <div class="tx-wide-card__img h-50 w-full mb-4" *ngIf="widget.showImage">
      <div
        class="h-50 w-full bg-slate-300"
        *ngIf="!item?.attributes.featureImage?.data"
      ></div>
      <img
        [src]="getImageUrl(item?.attributes.featureImage?.data)"
        [alt]="item?.attributes.featureImage?.data.name"
        *ngIf="item?.attributes.featureImage?.data"
        class="object-cover h-full object-center"
        loading="lazy"
      />
    </div>
  </a>
  <div
    class="tx-wide-card__categories mt-4"
    *ngIf="item?.attributes.article_categories?.data"
  >
    <a
      [routerLink]="['artigos', category.attributes.slug]"
      class="tx-wide-card__category text-red-motorbest-600 text-sm uppercase"
      *ngFor="
        let category of item?.attributes.article_categories?.data;
        let last = last
      "
    >
      {{ category.attributes.friendlyName }}<span *ngIf="!last">,</span>
    </a>
  </div>

  <a
    [routerLink]="[
      '/',
      activeLang,
      'artigos',
      item?.attributes.article_categories?.data[0]?.attributes?.slug,
      item?.attributes.slug + '-p' + item.id,
    ]"
    class="h4 text-left mb-2"
  >
    {{ item?.attributes.title }}
  </a>
  <div class="text-[#989797] lowercase text-md">
    {{ item?.attributes.createdAt | date }}
  </div>
</div>
