<div [class]="classes">
  <ng-container *ngFor="let widget of widgets">
    <widget-image
      *ngIf="widget.__component === 'widgets.image'"
      [widget]="widget"
      wrapper="'div'"
      classes="tx-footer__logo mb-6"
      imageClasses="max-h-14 w-auto"
    ></widget-image>

    <widgets-editor-text
      *ngIf="widget.__component === 'widgets.editor-text'"
      [widget]="widget"
      wrapper="'div'"
      [elementClasses]="'text-sm leading-base mb-4 text-gray30-500'"
    ></widgets-editor-text>

    <widget-block-title-description
      *ngIf="widget.__component === 'widgets.block-title-description'"
      [widget]="widget"
      wrapper="'div'"
      [classes]="'tx-footer__column--block mb-8'"
      [elementClasses]="'text-sm leading-base mb-4 text-gray30-500'"
    ></widget-block-title-description>

    <widget-text
      *ngIf="widget.__component === 'widgets.text'"
      [widget]="widget"
      wrapper="'div'"
      [elementClasses]="'tx-footer__column--block mb-8'"
    ></widget-text>
  </ng-container>
</div>
