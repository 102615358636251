import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { TXFooterModule } from '@layout/common/footer/footer.module';
import { TXHeaderModule } from '@layout/common/header/header.module';
import { SharedModule } from '@shared/shared.module';
import { SiteComponent } from './site.component';

@NgModule({
  declarations: [SiteComponent],
  imports: [
    CommonModule,
    RouterModule,
    FuseLoadingBarModule,
    SharedModule,
    TXHeaderModule,
    TXFooterModule,
  ],
  exports: [SiteComponent, TXHeaderModule, TXFooterModule],
})
export class SiteLayoutModule {}
