import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from './footer.component';
import { ColumnComponent } from './components/column/column.component';

@NgModule({
  declarations: [FooterComponent, ColumnComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [FooterComponent],
})
export class TXFooterModule {}
