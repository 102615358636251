<div class="tx-site-horizontal-navigation-wrapper">
  <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
    <!-- Skip the hidden items -->
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
      <!-- Basic -->
      <ng-container *ngIf="item.type === 'basic'">
        <tx-site-horizontal-navigation-basic-item
          class="tx-site-horizontal-navigation-menu-item"
          [item]="item"
          [name]="name"
        ></tx-site-horizontal-navigation-basic-item>
      </ng-container>

      <!-- Branch: aside, collapsable, group -->
      <ng-container
        *ngIf="
          item.type === 'aside' ||
          item.type === 'collapsable' ||
          item.type === 'group'
        "
      >
        <tx-site-horizontal-navigation-branch-item
          class="tx-site-horizontal-navigation-menu-item"
          [item]="item"
          [name]="name"
        ></tx-site-horizontal-navigation-branch-item>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
