<div
  class="tx-footer__top flex justify-center w-full pt-12 pb-14 bg-gray60-500"
>
  <div
    class="tx-footer__top--inner max-w-screen-xl w-full px-14 grid grid-cols-1 gap-7 sm:grid-cols-9"
  >
    <div class="col-span-1 hidden md:flex"></div>
    <div class="col-span-1 sm:col-span-4 sm:mr-30">
      <h4 class="text-gray10-500 sm:mb-6 w-full text-base sm:h4">
        {{ "Subscribe to our newsletter" | transloco }}
      </h4>
      <form
        action=""
        [formGroup]="newsletterForm"
        class="flex flex-col sm:flex-row"
      >
        <!-- Email field -->
        <mat-form-field class="w-full">
          <input
            id="email"
            matInput
            [formControlName]="'email'"
            placeholder="Email"
            type="email"
          />
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          class="sm:ml-4 rounded-none border-0 max-h-16 flex w-full sm:w-36 sm:max-w-max bg-primary-500"
          style="height: 50px !important"
          (click)="subscribe()"
          [disabled]="newsletterForm.invalid"
        >
          {{ "Subscribe" | transloco }}
        </button>
      </form>
    </div>

    <div class="col-span-1 sm:col-span-2">
      <h4 class="text-gray10-500 mb-6 w-full text-base sm:h4">
        {{ "Follow us on social media" | transloco }}
      </h4>
      <div class="footer__social">
        <a
          [href]="socialLinks.fb"
          target="_blank"
          rel="nofollow"
          class="footer__social--item"
        >
          <mat-icon svgIcon="feather:facebook"></mat-icon>
        </a>
        <a
          [href]="socialLinks.insta"
          target="_blank"
          rel="nofollow"
          class="footer__social--item"
        >
          <mat-icon svgIcon="feather:instagram"></mat-icon>
        </a>
        <a
          [href]="socialLinks.youtube"
          target="_blank"
          rel="nofollow"
          class="footer__social--item"
        >
          <mat-icon svgIcon="feather:youtube"></mat-icon>
        </a>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-2">
      <h4 class="text-gray10-500 mb-6 w-full text-base sm:h4">
        {{ "Tours" | transloco }}
      </h4>
      <div class="footer__social">
        <a
          [href]="socialLinks.fb_tours"
          target="_blank"
          rel="nofollow"
          class="footer__social--item"
        >
          <mat-icon svgIcon="feather:facebook"></mat-icon>
        </a>
        <a
          [href]="socialLinks.insta_tours"
          target="_blank"
          rel="nofollow"
          class="footer__social--item"
        >
          <mat-icon svgIcon="feather:instagram"></mat-icon>
        </a>
      </div>
    </div>
    <div class="col-span-1 hidden md:flex"></div>
  </div>
</div>
