import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Data } from '@angular/router';
import { FacetService } from '@core/facet/facet.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() facets: Data;
  @Input() solrFacets: Data;
  @Input() facetsSelected: Data;

  @Output() filter: EventEmitter<Data> = new EventEmitter();

  filterForm: UntypedFormGroup;
  filterFields: Data = [];

  private _unsubscribeAll: Subject<Data> = new Subject<Data>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _facetService: FacetService,
  ) {}

  ngOnInit(): void {
    this.exchangeFilterInputOnChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.exchangeFilterInputOnChange();
  }

  exchangeFilterInputOnChange() {
    // Create the form
    this.filterForm = this._formBuilder.group({});
    this.filterFields = [];

    this.facets.filterField.forEach((facet: Data) => {
      if (facet.fieldType === 'range') {
        this.filterForm.addControl(facet.solrFilterKey, new FormGroup({}));
        const nFb = this.filterForm.get(
          facet.solrFilterKey,
        ) as UntypedFormGroup;
        nFb.addControl(
          `${facet.solrFilterKey}_Min`,
          this._formBuilder.control(''),
        );
        nFb.addControl(
          `${facet.solrFilterKey}_Max`,
          this._formBuilder.control(''),
        );
      } else {
        this.filterForm.addControl(
          facet.solrFilterKey,
          this._formBuilder.control(''),
        );
      }
    });

    this.filterForm.patchValue(this.facetsSelected, { emitEvent: false });
    this.handleFilterData();

    this.filterForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.filter.emit(this.filterForm.value);
      });
  }

  handleFilterData() {
    const facetFields = this.solrFacets.facet_fields;
    const facetValues = [];

    this.facets.filterField.forEach((facet) => {
      const facetField = facetFields[facet.solrFilterKey];
      facetValues[facet.solrFilterKey] = {
        label: facet.Label,
        type: facet.fieldType,
        rangeLabel: facet.rangeLabel,
        key: facet.solrFilterKey,
        values: [],
      };

      if (facet.fieldType == 'range') {
        facetValues[facet.solrFilterKey] = {
          label: facet.Label,
          type: facet.fieldType,
          rangeLabel: facet.rangeLabel,
          key: facet.solrFilterKey,
          key_min: `${facet.solrFilterKey}_Min`,
          key_Max: `${facet.solrFilterKey}_Max`,
          is_currency: facet.solrFilterKey === 'price_f',
          values: [],
        };
      }

      facetField.forEach((facetValue: any, index: number) => {
        if (index % 2 === 0) {
          if (facetField[index + 1] > 0) {
            facetValues[facet.solrFilterKey].values.push({
              value: facetValue,
              count: facetField[index + 1],
            });
          }
        }
      });

      this.filterFields.push(facetValues[facet.solrFilterKey]);
    });
  }
}
