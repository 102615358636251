import { Injectable } from '@angular/core';
import { LocalesService } from '@core/transloco/locales.service';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from '@shared/components/dialogs/dialog.service';
import { DIALOGS } from '@shared/components/dialogs/dialogs';
import { ApiService } from '@targx/services/api/api.service';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _settings$: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _settings: any;

  constructor(
    private api: ApiService,
    private _localesService: LocalesService,
    private translocoSerice: TranslocoService,
    private dialogService: DialogService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get settings$() {
    return this._settings$.asObservable();
  }

  get settings() {
    return this._settings;
  }

  set settings(value) {
    this._settings = value;
    this._settings$.next(value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  get(): Observable<any> {
    const data = {
      locale: 'pt-PT',
      'populate[0]': '*',
      'populate[SEO][populate]': '*',
      'populate[SettingField][populate]': '*',
      'populate[localizations][populate]': '*',
      'populate[logoDark][populate]': '*',
      'populate[logoWhite][populate]': '*',
    };

    return this.api.get(`/setting`, data).pipe(
      tap((result) => {
        this.settings = result;
      }),
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Dialogs
  // -----------------------------------------------------------------------------------------------------

  openSignInDialog() {
    this.dialogService.open(
      DIALOGS.signIn,
      {
        autoFocus: false,
        disableClose: !true,
        panelClass: 'fuse-confirmation-dialog-panel',
        width: '432px',
        minHeight: '608px',
        maxHeight: '608px',
      },
      () => {
        console.log('Add product dialog closed');
      },
    );
  }
}
