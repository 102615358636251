<div class="tx-card__wrapper {{ classes }} w-full mb-11">
  <a [routerLink]="[
      '/',
      activeLang,
      'marketplace',
      item?.attributes.product_category?.data.attributes.slug,
      item.attributes.slug + '-p' + item.id,
    ]">
    <div class="tx-wide-card__img h-[200px] w-full mb-4" *ngIf="widget.showImage">
      <div class="max-h-[270px] w-full bg-slate-300" *ngIf="!item?.attributes.images?.data"></div>

      <img [src]="getImageUrl(item?.attributes.images?.data[0])"
        [alt]="item?.attributes.images?.data[0].attributes.caption" *ngIf="item?.attributes.images?.data?.length"
        class="object-cover h-full object-center" loading="lazy" />
    </div>

    <h4
      class="flex justify-center text-left text-black-motorbest-500 font-normal leading-3xl mb-2 h-12 overflow-hidden text-2xl"
      *ngIf="widget.showName">
      {{ item.attributes.year }} {{ item.attributes.name }}
    </h4>

    <div class="text-md leading-2xl font-semibold text-center mb-2" *ngIf="widget.showPrice">
      <ng-container *ngIf="!item.attributes.price">{{
        "Sob Consulta" | transloco
        }}</ng-container>
      <ng-container *ngIf="item.attributes.price">{{
        item.attributes.price | currency: "EUR"
        }}</ng-container>
    </div>
    <div class="text-sm leading-base font-light text-center flex justify-center items-center" *ngIf="widget.showCity">
      <img src="assets/images/flags/{{ item?.attributes?.country ?? '' | uppercase}}.svg" alt="Location"
        class="w-4 h-3 mr-2" />
      {{ item?.attributes?.city?.data?.attributes?.name ?? item?.attributes?.city_custom ?? '' }},
      {{ item?.attributes?.country ?? ''}}
    </div>
  </a>
</div>