<!--  -->
<ng-container *ngIf="widget.length === 1">
  <div class="widget-banner mx-auto w-full" [ngClass]="{ 'max-w-screen-xl': !widget[0].Columns.Fullwidth }">
    <ng-container *ngIf="widget.url">
      <a [href]="widget.url" class="widget-banner__url"></a>
    </ng-container>

    <img *ngIf="widget[0]?.images.data" [src]="getImageUrlFull(widget[0]?.images.data[0])" [alt]="widget[0].title"
      class="widget-banner__image" loading="lazy" />

    <ng-container *ngIf="widget[0]?.videoSource?.rawData">
      <div class="video-wrapper" [innerHTML]="sanitizeHtml(widget[0]?.videoSource?.rawData)"></div>
    </ng-container>

    <ng-container *ngIf="widget[0]?.internalVideo">
      <div class="video-wrapper">
        <video controls muted loop>
          <source [src]="getUrl(widget[0]?.internalVideo.url)" [type]="widget[0]?.internalVideo.mime" />
          Your browser does not support the video tag.
        </video>
      </div>
    </ng-container>

    <div class="widget-banner__content max-w-screen-xl">
      <h1 class="widget-banner__title">
        {{ title || widget[0].title | titlecase | transloco }}
      </h1>
      <p class="widget-banner__description">
        {{ description || widget[0].description }}
      </p>
      <button mat-stroked-button
        class="widget-banner__button rounded-sm my-4 text-white hidden md:block hover:bg-red-motorbest-600"
        *ngIf="widget[0]?.buttonName">
        {{ widget[0]?.buttonName | transloco }}
      </button>
      <ng-container *ngIf="widget[0].showSearch">
        <div class="flex mt-10">
          <mat-form-field class="rounded-none w-auto min-w-96 border-0">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput [formControl]="searchInputControl" [autocomplete]="'off'"
              [placeholder]="'What are you looking for?' | transloco" />
            <button matSuffix
              class="bg-red-motorbest-500 hover:bg-red-motorbest-400 absolute -right-[16px] h-[50px] min-w-[50px] rounded-none">
              <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_outline:arrow-right'"></mat-icon>
            </button>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- Slider swiper -->
<ng-container *ngIf="widget.length > 1">
  <swiper-container txSwiper #swiper [config]="config" [init]="false" autoplay="true" class="w-full">
    <ng-container *ngFor="let slider of widget; let i = index">
      <swiper-slide *ngIf="slider.isActive">
        <div class="widget-banner">
          <ng-container *ngIf="slider.url">
            <a [href]="slider.url" class="widget-banner__url"></a>
          </ng-container>
          <img *ngIf="slider.images.data" [src]="getImageUrlFull(slider.images.data[0] || slider.images.data)"
            [alt]="slider?.title" class="widget-banner__image" loading="lazy" />

          <ng-container *ngIf="slider?.videoSource?.rawData">
            <div class="video-wrapper">
              <ng-container *ngIf="
                  slider?.videoSource?.rawData?.provider_name === 'YouTube'
                " [inner]>
                <youtube-player [videoId]="getVideIidAddToList(slider?.videoSource.url, i)"
                  placeholderImageQuality="high" (ready)="onYTReady($event)" class="w-full" [width]="'100%'"
                  [height]="600" [playerVars]="ytplayervars" />
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="slider?.internalVideo">
            <div class="video-wrapper">
              <video muted>
                <source [src]="getUrl(slider?.internalVideo.url)" [type]="slider?.internalVideo.mime" />
                Your browser does not support the video tag.
              </video>
            </div>
          </ng-container>

          <div class="widget-banner__content max-w-screen-xl">
            <h1 class="widget-banner__title">{{ slider?.title }}</h1>
            <p class="widget-banner__description">{{ slider?.description }}</p>
            <button mat-stroked-button
              class="widget-banner__button rounded-sm my-4 hidden md:block text-white hover:bg-red-motorbest-600"
              *ngIf="slider?.buttonName">
              {{ slider?.buttonName }}
            </button>
          </div>
        </div>
      </swiper-slide>
    </ng-container>
  </swiper-container>
  <div class="widget-banner__bullets max-w-screen-xl m-auto">
    <ng-container *ngFor="let slider of widget; let i = index">
      <div class="widget-banner__bullets--bullet cursor-pointer" (click)="handleSelectSlider(i)"
        [ngClass]="{ active: selectedSliderId === i }" *ngIf="slider.isActive"></div>
    </ng-container>
  </div>
</ng-container>
