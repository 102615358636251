import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';

@Injectable()
export class FuseSplashScreenService {
  /**
   * Constructor
   */
  constructor(
    @Inject(DOCUMENT) private _document: any,
    private _router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    // Hide it on the first NavigationEnd event
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        this.hide();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the splash screen
   */
  show(): void {
    if (isPlatformBrowser(this.platformId)) {
      this._document.body.classList.remove('fuse-splash-screen-hidden');
    }
  }

  /**
   * Hide the splash screen
   */
  hide(): void {
    if (isPlatformBrowser(this.platformId)) {
      this._document.body.classList.add('fuse-splash-screen-hidden');
    }
  }
}
