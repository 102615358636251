import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { FacetService } from '@core/facet/facet.service';
import { PaidMediaService } from '@shared/widgets/pai-media/paid-media.service';
import { environment } from 'environments/environment';
import { combineLatest } from 'rxjs';
import SimpleLightbox from 'simplelightbox';

/**
 * SWIPER
 */
import { register } from 'swiper/element/bundle';
register();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  lightbox;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(
    private router: Router,
    private _facetService: FacetService,
    private _paidMediaService: PaidMediaService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      combineLatest([
        this._facetService.get(),
        this._paidMediaService.get(),
      ]).subscribe();
    }

    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void {};
      //console.debug = function (): void { };
      console.warn = function (): void {};
      console.info = function (): void {};
    }
  }

  ngAfterViewInit(): void {
    register();
    if (isPlatformBrowser(this.platformId)) {
      this.lightbox = new SimpleLightbox('.lighbox-image');
    }

    this.router.events.subscribe((event) => {
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          this.lightbox.refresh();
        }, 500);
      }
    });
  }
}
