<div
  class="w-full max-w-screen-xl mx-auto px-4 md:px-14 py-7"
  [ngClass]="classes"
>
  <header
    class="grid gap-4 mb-4"
    [ngClass]="{
      'grid-cols-1': widget.numberOfColumns === 1,
      'grid-cols-2': widget.numberOfColumns === 2,
      'grid-cols-3': widget.numberOfColumns === 3,
      'grid-cols-4': widget.numberOfColumns === 4,
      'grid-cols-5': widget.numberOfColumns === 5,
      'grid-cols-6': widget.numberOfColumns === 6,
      'grid-cols-7': widget.numberOfColumns === 7,
    }"
    *ngIf="widget.name"
  >
    <h5
      class="pb-3 widget-title"
      [ngClass]="{
        'col-span-1': widget.numberOfColumns === 1,
        'col-span-1': widget.numberOfColumns === 2,
        'col-span-2': widget.numberOfColumns === 3,
        'col-span-3': widget.numberOfColumns === 4,
        'col-span-4': widget.numberOfColumns === 5,
        'col-span-5': widget.numberOfColumns === 6,
        'col-span-6': widget.numberOfColumns === 7,
      }"
    >
      <span class="pb-3">{{ widget.name }}</span>
    </h5>
    <!-- <a href="#"
      class="col-span-1 text-primary flex items-center justify-end text-base font-semibold hover:opacity-50 hover:transition-all">
      {{ "Ver tudo" | transloco }}
      <mat-icon svgIcon="mat_solid:arrow_forward_ios" class="text-primary icon-size-4 ml-2"></mat-icon>
    </a> -->
  </header>

  <div
    class="grid grid-cols-1 sm:grid-cols-2 gap-4"
    [ngClass]="{
      'lg:grid-cols-1': widget.numberOfColumns === 1,
      'lg:grid-cols-2': widget.numberOfColumns === 2,
      'lg:grid-cols-3': widget.numberOfColumns === 3,
      'lg:grid-cols-4': widget.numberOfColumns === 4,
      'lg:grid-cols-5': widget.numberOfColumns === 5,
      'lg:grid-cols-6': widget.numberOfColumns === 6,
      'lg:grid-cols-7': widget.numberOfColumns === 7,
    }"
  >
    <article-card
      class="col-span-auto"
      *ngFor="let item of widget.items.data"
      [widget]="widget"
      [item]="item"
    ></article-card>
  </div>
</div>
