import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { dialogList } from './dialogs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  open(component?: string, data?: MatDialogConfig, action?: any) {
    if (!component) return;

    if (!dialogList[component]) return;

    let dialogConfig = new MatDialogConfig();
    let dialogRef;

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig = Object.assign(dialogConfig, data);

    dialogRef = this.dialog.open(dialogList[component], data);

    dialogRef.afterClosed().subscribe((res) => {
      console.log(
        '🚀 ~ DialogService ~ dialogRef.afterClosed ~ res:',
        res,
        res.data,
      );
      if (action) {
        return action(res);
      }

      if (res && res.step) {
        action && action(res);

        const dconfig = new MatDialogConfig();
        dconfig.width = res.width || '564px';
        dconfig.height = res.height || '376px';
        dconfig.data = res.data;

        return this.open(res.step, dconfig);
      }
    });
  }
}
