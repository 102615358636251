import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { SeoService } from './seo.service';

@Injectable()
export class SeoInterceptor implements HttpInterceptor {
  constructor(private seoService: SeoService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((resp) => {
        if (resp instanceof HttpResponse) {
          // if (
          //   resp.body.data &&
          //   resp.body.data.attributes &&
          //   resp.body.data.attributes.SEO
          // ) {
          //   this.seoService.handleSeoFromApi(resp.body.data.attributes.SEO);
          // }
          // if (
          //   Array.isArray(resp.body.data) &&
          //   resp.body.data[0]?.attributes &&
          //   resp.body.data[0]?.attributes.SEO
          // ) {
          //   this.seoService.handleSeoFromApi(resp.body.data[0]?.attributes.SEO);
          // }
        }
      }),
    );
  }
}
