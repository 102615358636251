<div class="w-full mx-auto my-10" *ngIf="form">
  <div
    class="lg:col-span-12 md:col-span-6 sm:col-span-3 xl:col-span-12 hidden"
  ></div>
  <div
    class="col-span-12 sm:col-span-3 md:col-span-6 lg:col-span-12 xl:col-span-6 hidden"
  ></div>
  <div class="col-span-1 md:col-span-12 hidden"></div>
  <!-- Alert -->
  <fuse-alert
    class="mt-8"
    *ngIf="showAlert"
    [appearance]="'outline'"
    [showIcon]="false"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <form
    class="grid grid-cols-12 gap-x-4"
    [formGroup]="form"
    #dynamicNgForm="ngForm"
  >
    <ng-container
      *ngFor="let field of widget?.formulario?.data?.attributes?.Fields"
    >
      <mat-form-field
        *ngIf="field.__component === 'forms.text'"
        [class]="getResponviceClasses(field)"
      >
        <mat-label *ngIf="field.ShowLabel">{{ field.Label }}</mat-label>
        <input
          matInput
          [type]="field.Type ?? 'text'"
          [required]="field.Required"
          [placeholder]="field.label"
          [formControlName]="field.Name"
          class="w-full"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="field.__component === 'forms.textarea'"
        [class]="getResponviceClasses(field)"
      >
        <mat-label *ngIf="field.ShowLabel">{{ field.Label }}</mat-label>
        <textarea
          matInput
          [required]="field.Required"
          [placeholder]="field.label"
          [formControlName]="field.Name"
          [rows]="field.Rows"
          class="w-full"
        ></textarea>
      </mat-form-field>

      <mat-form-field
        *ngIf="field.__component === 'forms.dropdown'"
        [class]="getResponviceClasses(field)"
      >
        <mat-label *ngIf="field.ShowLabel">{{ field.Label }}</mat-label>
        <mat-select
          class="border-red-motorbest-600 text-red-motorbest-600 rounded-none"
          [formControlName]="field.Name"
        >
          <ng-container *ngFor="let item of field.Options.trim().split('\n')">
            <mat-option [value]="item" *ngIf="item">
              {{ item | transloco }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="field.__component === 'forms.checkbx'">
        <mat-checkbox
          [formControlName]="field.Name"
          [color]="'primary'"
          [class]="getResponviceClasses(field)"
        >
          {{ field.Label | transloco }}
        </mat-checkbox>
      </ng-container>

      <ng-container *ngIf="field.__component === 'forms.radio'">
        <div
          class="form-field-radio flex flex-col mt-5"
          [class]="getResponviceClasses(field)"
        >
          <mat-label *ngIf="field.ShowLabel" class="text-md font-medium"
            >{{ field.Label }}
            <ng-container *ngIf="field.Required">*</ng-container></mat-label
          >
          <mat-radio-group
            aria-labelledby="{{ field.Label }}"
            [formControlName]="field.Name"
            class="gap-4 flex"
            [ngClass]="{
              'flex-col': field.modo_visual === 'Vertical'
            }"
          >
            <ng-container *ngFor="let opt of field.Options">
              <mat-radio-button [color]="'primary'" [value]="opt.Value">{{
                opt.Label
              }}</mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>
      </ng-container>

      <ng-container *ngIf="field.__component === 'widgets.editor-text'">
        <div [class]="getResponviceClasses(field)">
          <widgets-editor-text
            [widget]="field"
            elementClasses="mt-14 mb-14"
          ></widgets-editor-text>
        </div>
      </ng-container>

      <ng-container *ngIf="field.__component === 'forms.button'">
        <!-- Submit button -->
        <button
          type="reset"
          class="fuse-mat-button-large mt-6"
          [ngClass]="getResponviceClasses(field)"
          mat-stroked-button
          *ngIf="field.Type === 'reset'"
        >
          {{ field.Label }}
        </button>

        <!-- Submit button -->
        <button
          class="fuse-mat-button-large mt-6"
          [ngClass]="getResponviceClasses(field)"
          mat-flat-button
          [color]="'primary'"
          [disabled]="form.disabled"
          (click)="onSubmit()"
          *ngIf="field.Type === 'submit'"
        >
          <span *ngIf="!form.disabled">
            {{ field.Label }}
          </span>
          <mat-progress-spinner
            *ngIf="form.disabled"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
        </button>
      </ng-container>
    </ng-container>
  </form>
</div>
