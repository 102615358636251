import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { NoAuthGuard } from '@core/auth/guards/noAuth.guard';
import { LayoutComponent } from '@layout/layout.component';
import { InitialDataResolver, InitialSettingsResolver } from './app.resolvers';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/pt' },

  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialSettingsResolver,
    },
    data: {
      layout: 'site',
    },
    children: [
      {
        path: ':lang',
        resolve: {
          initialData: InitialDataResolver,
        },
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/home/home.module').then((m) => m.HomeModule),
          },
          {
            path: 'artigos',
            loadChildren: () =>
              import('./pages/articles/articles.module').then(
                (m) => m.ArticlesModule,
              ),
          },
          {
            path: 'news',
            loadChildren: () =>
              import('./pages/articles/articles.module').then(
                (m) => m.ArticlesModule,
              ),
          },
          {
            path: 'marketplace',
            loadChildren: () =>
              import('./pages/marketplace/marketplace.module').then(
                (m) => m.MarketplaceModule,
              ),
          },
          {
            path: 'automobilia',
            loadChildren: () =>
              import('./pages/automobilia/automobilia.module').then(
                (m) => m.AutomobiliaModule,
              ),
          },
          {
            path: 'services',
            loadChildren: () =>
              import('./pages/services/services.module').then(
                (m) => m.ServicesModule,
              ),
          },
          {
            path: 'tours',
            loadChildren: () =>
              import('./pages/tours/tours.module').then((m) => m.ToursModule),
          },
          {
            path: 'preview',
            loadChildren: () =>
              import('./pages/preview/preview.module').then(
                (m) => m.PreviewModule,
              ),
          },
        ],
      },
    ],
  },

  {
    path: '',
    //canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialSettingsResolver,
    },
    data: {
      layout: 'site',
    },
    children: [
      {
        path: ':lang',
        resolve: {
          initialData: InitialDataResolver,
        },
        children: [
          {
            path: 'confirmation-required',
            loadChildren: () =>
              import(
                'app/modules/auth/confirmation-required/confirmation-required.module'
              ).then((m) => m.AuthConfirmationRequiredModule),
          },
          {
            path: 'forgot-password',
            loadChildren: () =>
              import(
                'app/modules/auth/forgot-password/forgot-password.module'
              ).then((m) => m.AuthForgotPasswordModule),
          },
          {
            path: 'reset-password',
            loadChildren: () =>
              import(
                'app/modules/auth/reset-password/reset-password.module'
              ).then((m) => m.AuthResetPasswordModule),
          },
          {
            path: 'sign-in',
            loadChildren: () =>
              import('@modules/auth/authentication/authentication.module').then(
                (m) => m.AuthenticationModule,
              ),
          },
          {
            path: 'sign-up',
            loadChildren: () =>
              import('@modules/auth/authentication/authentication.module').then(
                (m) => m.AuthenticationModule,
              ),
          },
          {
            path: 'authentication',
            loadChildren: () =>
              import('@modules/auth/authentication/authentication.module').then(
                (m) => m.AuthenticationModule,
              ),
          },
          {
            path: 'private',
            canMatch: [AuthGuard],
            loadChildren: () =>
              import('@modules/private/private.module').then(
                (m) => m.PrivateModule,
              ),
          },
        ],
      },
    ],
  },

  /**
   * When a route does not exist, redirect to home
   */
  { path: '**', pathMatch: 'full', redirectTo: '/pt' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
