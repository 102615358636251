import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { SharedModule } from '@shared/shared.module';
import { LanguagesModule } from '../languages/languages.module';
import { MessagesModule } from '../messages/messages.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { QuickChatModule } from '../quick-chat/quick-chat.module';
import { SearchModule } from '../search/search.module';
import { ShortcutsModule } from '../shortcuts/shortcuts.module';
import { UserModule } from '../user/user.module';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    LanguagesModule,
    MessagesModule,
    NotificationsModule,
    QuickChatModule,
    SearchModule,
    ShortcutsModule,
    FuseNavigationModule,
    UserModule,
  ],
  exports: [HeaderComponent],
})
export class TXHeaderModule {}
