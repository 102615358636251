import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'widget-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnInit {
  @Input() widget: any;
  @Input() classes: string;
  @Input() wrapper = 'div';
  @Input() imageClasses: string;
  @Input() ref: any;

  @HostBinding('class') hostClass = '';

  ngOnInit(): void {
    // const { Columns } = this.widget;
    // console.log("🚀 ~ file: image.component.ts:17 ~ ImageComponent ~ ngOnInit ~ this.widget:", Columns)
    // const auxClasses = [
    //   this.classes,
    //   'flex',
    //   'flex-auto',
    // ];
    // if (Columns?.Fullwidth) {
    //   auxClasses.push('w-full col-span-12');
    // } else {
    //   if (Columns?.Screen_XS) {
    //     auxClasses.push(`${Columns.Screen_XS}`);
    //   } else {
    //     //auxClasses.push('col-span-12');
    //   }
    //   if (Columns?.Screen_XL) {
    //     auxClasses.push(`xl:${Columns.Screen_XL}`);
    //   }
    //   if (Columns?.Screen_LG) {
    //     auxClasses.push(`lg:${Columns.Screen_LG}`);
    //   }
    //   if (Columns?.Screen_MD) {
    //     auxClasses.push(`md:${Columns.Screen_MD}`);
    //   }
    //   if (Columns?.Screen_SM) {
    //     auxClasses.push(`sm:${Columns.Screen_SM}`);
    //   }
    // }
    // this.hostClass = auxClasses.join(' ');
    // console.log("🚀 ~ file: image.component.ts:47 ~ ImageComponent ~ ngOnInit ~ this.classes:", this.hostClass)
  }

  // getImageUrl(image) {
  //   return `${environment.assets}${image?.data?.attributes?.url}` || '';
  // }

  getImageUrl(image, format = 'large') {
    const defaultImage = image?.data?.attributes?.url;

    if (format === 'full') {
      return defaultImage;
    }

    if (
      image?.data?.attributes?.formats &&
      image?.data?.attributes?.formats[format]
    ) {
      return `${image?.data?.attributes?.formats[format]?.url}`;
    }

    return defaultImage;
  }
}
