import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';
import { FooterService } from './footer.service';

@Component({
  selector: 'tx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: fuseAnimations,
})
export class FooterComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  footer: any;

  newsletterForm: UntypedFormGroup;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private footerService: FooterService,
  ) {
    this.footerService.get().subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.newsletterForm = this._formBuilder.group({
      email: ['', Validators.required],
    });

    // Subscribe to navigation data
    this.footerService.footer$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((footer) => {
        this.footer = footer;
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
