import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { ApiService } from '@targx/services/api/api.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, ReplaySubject, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private api: ApiService,
    private translocoService: TranslocoService,
  ) {
    // this.translocoService.langChanges$.subscribe((lang) => {
    //   this.getMenu(1).subscribe();
    // });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    return this._httpClient.get<Navigation>('api/common/navigation').pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      }),
    );
  }

  exchangeMenuSchema(item: any) {
    const lang = this.translocoService.getActiveLang();
    return {
      id: item.id,
      title: item.attributes.title,
      type: item.attributes.children?.data?.length ? 'group' : 'basic',
      link: `/${lang}${item.attributes.url}`,
      base_link: item.attributes.url,
      target: item.attributes.target,
      icon: item.attributes.children?.data?.length ? 'arrow_drop_down' : '',
      children:
        item.attributes.children?.data.map((child) =>
          this.exchangeMenuSchema(child),
        ) || [],
    } as FuseNavigationItem;
  }

  getMenu(id: number) {
    const lang = this.translocoService.getActiveLang();

    return this.api
      .get(`/menus/?nested&populate=*&filters%5Bslug%5D=menu-${lang}`)
      .pipe(
        map(({ data }) => {
          return data[0].attributes?.items.data.map((item) =>
            this.exchangeMenuSchema(item),
          );
        }),
        tap((navigation) => {
          this._navigation.next({ site: navigation } as Navigation);
        }),
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  setObservableNavigationByLang(): Observable<boolean> {
    this.translocoService.langChanges$.subscribe((lang) => {
      this.getMenu(1).subscribe();
    });
    return of(true);
  }
}
