import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap, takeUntil } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { SwiperContainer } from 'swiper/element';
import { A11y, Autoplay, Mousewheel } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
@Component({
  selector: 'widget-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent
  extends WidgetBaseComponent
  implements OnInit, AfterViewInit
{
  @Input() title?: string;
  @Input() description?: string;

  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  searchInputControl: UntypedFormControl = new UntypedFormControl();

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  YTVideo: any;

  autoPlaytime = 5000;
  autoplayStart = false;

  public config: SwiperOptions = {
    modules: [A11y, Mousewheel, Autoplay],
    loop: true,
    autoHeight: true,
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: this.autoPlaytime,
      disableOnInteraction: false,
    },
    speed: 400,
  };

  selectedSliderId = 0;

  public ytplayervars: YT.PlayerVars = {
    autoplay: 1,
    controls: 0,
    showinfo: 0,
    rel: 0,
    modestbranding: 1,
    loop: 1,
    enablejsapi: 1,
    // origin: enviorment window.location.origin,
  };

  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super();
  }

  ngOnInit(): void {
    this.widget = this.widget.filter((res) => res.isActive);
    const auxAutoPlaytime =
      this.widget.map((res) => res.SlideAutoplayTime) ?? [];
    this.autoPlaytime = Math.max(...auxAutoPlaytime);

    if (this.autoPlaytime > 0) {
      this.config.autoplay = {
        delay: this.autoPlaytime,
      };
    }

    this.widget = this.widget.map((res) => {
      res.videoSource = JSON.parse(res.videoSource) || '';
      if (res.embedded === 'External') {
        res.images.data.map((image) => {
          if (image.attributes.mime.includes('video/')) {
            res.internalVideo = image.attributes;
          }
        });
      }

      return res;
    });

    this.route.queryParams.subscribe((data) => {
      if (data?.s) {
        this.searchInputControl.setValue(data.s, { emitEvent: false });
      }
    });

    // Subscribe to search input field value changes
    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((query) => {
          this.search.emit(query);
          return of(query);
          // Search
          //this._contactsService.searchContacts(query),
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.swiper) {
        this.swiper.nativeElement.swiper.autoplay.start();
        console.log(
          '🚀 ~ ngAfterViewInit ~ this.swiper:',
          this.swiper,
          this.config,
        );

        this.swiper.nativeElement.addEventListener(
          'swiperslidechange',
          (event: any) => {
            const previousIndex = event.detail[0].previousRealIndex;
            const realIndex = event.detail[0].realIndex;
            const allVideos = document.querySelectorAll('.swiper-slide video');
            const itemSelected = this.widget[realIndex];
            this.selectedSliderId = realIndex;

            console.log(
              '🚀 ~ ngAfterViewInit ~ this.swiper:',
              this.swiper.nativeElement.swiper.autoplay.timeLeft,
            );

            allVideos.forEach((video: HTMLMediaElement) => {
              video.pause();
              video.currentTime = 0;
              video.volume = 0;
            });

            /* SWIPER GET CURRENT AND PREV SLIDE (AND The VIDEO INSIDE) */
            const prevVideo: HTMLMediaElement = document.querySelector(
              `[data-swiper-slide-index="${previousIndex}"] video`,
            );
            const currentVideo: HTMLMediaElement = document.querySelector(
              `[data-swiper-slide-index="${realIndex}"] video`,
            );

            if (currentVideo) {
              if (prevVideo) prevVideo.pause();

              if (currentVideo) {
                currentVideo.volume = 0;
                currentVideo.play();
                if (itemSelected?.VideoDuration === 'UntilEnd') {
                  this.swiper.nativeElement.swiper.autoplay.stop();

                  currentVideo?.addEventListener('ended', () => {
                    this.swiper.nativeElement.swiper.slideNext();
                    this.swiper.nativeElement.swiper.autoplay.start();
                  });
                }
              }
            }

            if (this.YTVideo) {
              this.swiper.nativeElement.swiper.autoplay.stop();
              setTimeout(() => {
                this.YTVideo.playVideo();
              }, 1000);

              this.YTVideo.addEventListener('onStateChange', (event) => {
                if (event.data === 0) {
                  this.swiper.nativeElement.swiper.autoplay.resume();
                  this.swiper.nativeElement.swiper.slideNext();
                }
              });
            }
          },
        );
      }
    }
  }

  handleSelectSlider(index: number): void {
    this.selectedSliderId = index;
    this.swiper.nativeElement.swiper.slideTo(index);
  }

  getVideIid(video: any): string {
    const params = new URL(video).searchParams;
    return params.get('v');
  }

  getVideIidAddToList(video: any, i: number): string {
    return this.getVideIid(video);
  }

  onYTReady(event: any): void {
    //event.target.playVideo();
    this.swiper.nativeElement.swiper.autoplay.stop();
    this.YTVideo = event.target;
  }
}
