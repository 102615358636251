import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Directive({
  selector: '[txSwiper]',
})
export class SwiperDirective implements AfterViewInit {
  swiperElement: HTMLElement;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('config')
  config?: SwiperOptions;

  constructor(
    private el: ElementRef<SwiperContainer>,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    //this.swiperElement = el.nativeElement;
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      Object.assign(this.el.nativeElement, this.config);
      this.el.nativeElement.initialize();
    }
  }
}
