import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SwiperDirective } from './swiper.directive';

@NgModule({
  declarations: [SwiperDirective],
  imports: [CommonModule],
  exports: [SwiperDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SwiperModule {}
