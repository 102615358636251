<div
  class="col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12"
></div>

<div
  class="col-span-6 lg:col-span-6 md:col-span-12 sm:col-span-12 widgets_image xl:col-span-6"
></div>
<div
  class="col-span-12 lg:col-span-4 md:col-span-12 sm:col-span-12 widgets_image xl:col-span-4"
></div>

<div
  class="w-full mx-auto my-10 {{ widget.background }}"
  [ngClass]="{
    'max-w-screen-xl px-4 md:px-14': !widget?.fullWidth
  }"
>
  <div
    [class]="
      'grid grid-cols-1 md:grid-cols-12 items-center ' + widget.ColumnGap
    "
  >
    <ng-container *ngIf="widget.image?.data?.id">
      <widget-image
        [widget]="widget"
        classes="flex w-full h-full"
        imageClasses="flex w-full h-full object-cover"
        [class]="'col-span-1 md:col-span-6 ' + widget.ColumnLeft"
        [ngClass]="{ 'order-last': widget?.ColumnReverse }"
      ></widget-image>
    </ng-container>

    <ng-container *ngIf="widget.description">
      <div
        class="col-span-1 md:col-span-6 {{ widget.ColumnRight }} "
        [ngClass]="{
          'max-w-160': widget?.fullWidth,
          'order-first': widget?.ColumnReverse
        }"
      >
        <h3
          class="text-black-motorbest-500 font-bold mb-4 text-center md:w-1/2 mx-auto"
        >
          {{ widget.title }}
        </h3>
        <div
          class="text-black-motorbest-500 text-center"
          [innerHTML]="widget?.description"
        ></div>
      </div>
    </ng-container>
  </div>
</div>
