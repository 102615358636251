import { Component, Input, OnInit } from '@angular/core';
import { WidgetBaseComponent } from '@shared/widgets/widget-base/widget-base.component';
import { ProductSimple } from 'app/pages/marketplace/product.model';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent
  extends WidgetBaseComponent
  implements OnInit
{
  @Input() product: ProductSimple & { [key: string]: any };

  ngOnInit(): void {
    console.log('ProductCardComponent constructor', this.product);
  }

  get link(): string {
    return `/${this.activeLang}${this.product.link}`;
  }
}
