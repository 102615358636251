import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  private _mailchimpApiKey: string;
  private _mailchimpListId: string;
  private _mailchimpAudienceId: string;
  private _mailchimpServer: string;

  set serverKey(value: string) {
    this._mailchimpServer = value;
  }

  set apiKey(value: string) {
    this._mailchimpApiKey = value;
  }

  set listId(value: string) {
    this._mailchimpListId = value;
  }

  set audienceId(value: string) {
    this._mailchimpAudienceId = value;
  }

  constructor(private api: ApiService) {}

  addNewMember(params: any) {
    // mailchimp.setConfig({
    //   apiKey: this._mailchimpApiKey,
    //   server: this._mailchimpServer,
    // });

    // const result = mailchimp.lists.addListMember(this._mailchimpListId, {
    //   email_address: params?.email ?? params,
    //   status: 'subscribed',
    // });

    const data = {
      email: params?.email ?? params,
      list_id: '1',
      status: 'subscribed',
    };

    const dataFilter = {
      'filters[email]': params?.email ?? params,
    };

    return this.api.get('/newsletter-subscribers', dataFilter).pipe(
      switchMap((response: any) => {
        if (response.data.length === 0) {
          return this.api.post('/newsletter-subscribers', { data });
        }
        return response?.data;
      }),
    );
  }
}
