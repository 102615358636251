import { Component, Input, OnInit } from '@angular/core';
import { WidgetBaseComponent } from '@shared/widgets/widget-base/widget-base.component';

@Component({
  selector: 'article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
})
export class ArticleCardComponent
  extends WidgetBaseComponent
  implements OnInit
{
  @Input()
  item: any;

  constructor() {
    super();
  }

  ngOnInit() {}
}
