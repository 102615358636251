import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'widget-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoComponent extends WidgetBaseComponent implements OnInit {
  iframe: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.iframe = JSON.parse(this.widget?.externalVideo) || '';
    console.log('🚀 ~ VideoComponent ~ ngOnInit ~ this.iframe:', this.iframe);
    console.log(this.widget);
  }

  // sanitizeHtml(s) {
  //   return this.sanitizer.bypassSecurityTrustHtml(s);
  // }
}
