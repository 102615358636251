import { Component, Input, OnInit } from '@angular/core';
import { WidgetBaseComponent } from '@shared/widgets/widget-base/widget-base.component';

@Component({
  selector: 'widget-new-wide-card',
  templateUrl: './wide-card.component.html',
  styleUrls: ['./wide-card.component.scss'],
})
export class WideCardComponent extends WidgetBaseComponent implements OnInit {
  @Input()
  orientation: 'horizontal' | 'vertical' = 'horizontal';

  @Input()
  item: any;

  ngOnInit(): void {
    console.log(
      '🚀 ~ file: wide-card.component.ts:41 ~ WideCardComponent ~ ngOnInit ~ this.item:',
      this.item
    );
  }
}
