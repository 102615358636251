import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ReplaySubject, filter, map } from 'rxjs';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { PaidMediaService } from './paid-media.service';

// Função para calcular a taxa de cliques (CTR)
const calculateCTR = (image) => +image.clicks / +image.views;

@Component({
  selector: 'widget-pai-media',
  templateUrl: './pai-media.component.html',
  styleUrls: ['./pai-media.component.scss'],
})
export class PaiMediaComponent
  extends WidgetBaseComponent
  implements OnInit, AfterViewInit
{
  @Input() position = 'pos_1';
  @Input() page = '';

  // item$ = this.paidMediaService.items$.pipe(
  //   filter((items) => !!items),
  //   filter((items) => items.data.length > 0),
  //   mergeMap((items) => items.data),
  //   filter((item: any) => item.attributes.page === this.page),
  //   filter((item: any) => item.attributes.position === this.position),
  // );

  adsToShow$: ReplaySubject<any> = new ReplaySubject(1);

  constructor(private paidMediaService: PaidMediaService) {
    super();
  }

  ngOnInit(): void {
    this.paidMediaService.items$
      .pipe(
        filter((items) => !!items),
        filter((items) => items.data.length > 0),
        map((items) => items.data),
        map((items) => {
          const adds = items.filter(
            (item: any) =>
              item.attributes.page === this.page &&
              item.attributes.position === this.position,
          );

          return adds;
          // {

          //           let res
          //           const combineAds = adds.map((add) => {
          //             return {
          //               ...add,
          //               ctr: calculateCTR(add.attributes)
          //             }
          //           });
          //           const result = combineAds.sort((a, b) => a.ctr - b.ctr).slice(0, 1);
          //           return adds;}
        }),
        // filter((item: any) => item.attributes.page === this.page),
        // filter((item: any) => item.attributes.position === this.position),
      )
      .subscribe((res: any) => {
        this.adsToShow$.next(res[0]);
      });
  }

  ngAfterViewInit(): void {}

  log(s) {
    // console.log("🚀 ~ PaiMediaComponent ~ log ~ s:", s)
  }
}
