import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { NavigationService } from '@core/navigation/navigation.service';
import { SettingsService } from '@core/settings/settings.service';
import { LocalesService } from '@core/transloco/locales.service';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, combineLatest, forkJoin, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    // private _messagesService: MessagesService,
    // private _notificationsService: NotificationsService,
    // private _quickChatService: QuickChatService,
    // private _shortcutsService: ShortcutsService,
    private _navigationService: NavigationService,
    private _localesService: LocalesService,
    private translocoSerice: TranslocoService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    // Fork join multiple API endpoint calls to wait all of them to finish
    return this._localesService.locales$.pipe(
      switchMap((locales) => {
        const availableLangs = locales.map((locale) => ({
          id: locale.code.toLowerCase().split('-')[0],
          label: locale.name,
        }));
        const defaultLang = locales
          .find((locale) => locale.isDefault)
          .code.toLowerCase()
          .split('-')[0];
        const routeLang = route.params.lang;

        this.translocoSerice.setAvailableLangs(availableLangs.reverse());
        this.translocoSerice.setDefaultLang(defaultLang);
        this.translocoSerice.setActiveLang(routeLang ?? defaultLang);
        // this.translocoSerice.load(routeLang ?? defaultLang).toPromise();
        return combineLatest([
          this._navigationService.setObservableNavigationByLang(),
        ]);
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class InitialSettingsResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    private _settingsService: SettingsService,
    private _localesService: LocalesService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([this._localesService.get(), this._settingsService.get()]);
  }
}
