import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NgForm,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { UserService } from '@core/user/user.service';
import { FuseAlertType } from '@fuse/components/alert';
import { TranslocoService } from '@ngneat/transloco';
import { FormService } from '@shared/widgets/form-widget/form.service';
import { MarketplaceService } from 'app/pages/marketplace/marketplace.service';

@Component({
  selector: 'app-seller-contact-form',
  templateUrl: './seller-contact-form.component.html',
  styleUrls: ['./seller-contact-form.component.scss'],
})
export class SellerContactFormComponent implements OnInit {
  @ViewChild('signUpNgForm') signUpNgForm: NgForm;

  @Input() product: any;
  @Input() seller: any;

  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };

  signUpForm: UntypedFormGroup;
  showAlert = false;
  showPhone = false;

  public translocoSevice = inject(TranslocoService);
  public activeLang: string = this.translocoSevice.getActiveLang();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService,
    private _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private _marketplaceService: MarketplaceService,
    private _userService: UserService,
  ) {}

  ngOnInit(): void {
    // Create the form
    this.signUpForm = this._formBuilder.group({
      fullname: [''],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
      agreements: ['', Validators.requiredTrue],
    });
  }

  loading = false;
  togglePhone(): void {
    this.loading = true;
    this._marketplaceService
      .showPhone(this.product.id, this.product)
      .subscribe(() => {
        this.showPhone = true;
        this.loading = false;
      });
  }

  onSubmit(): void {
    //Return if the form is invalid
    if (this.signUpForm.invalid) {
      return;
    }

    // Disable the form
    this.signUpForm.disable();

    // // Hide the alert
    this.showAlert = false;

    const data = {
      formName: this.product.name,
      formData: {
        id: this.product.id,
        url: window.location.href,
        product: this.product.id,
        seller: this.seller.id,
        ...this.signUpForm.value,
      },
    };

    const saveSeller = { ...this.seller.data };
    delete saveSeller.attributes?.products;
    delete saveSeller.attributes?.users_permissions_users;
    delete saveSeller.attributes?.agreements_phone;
    delete saveSeller.attributes?.photo;

    const dataVendor: any = {
      data: {
        title: `Nova mensagem no anúncio ${this.product.name} de ${this.signUpForm.value.firstname} ${this.signUpForm.value.lastname}`,
        message: this.signUpForm.value.message,
        receiver: this.seller.data.id,
        product: this.product.id,
        guest_email: this.signUpForm.value.email,
        data: {
          url: window.location.href,
          seller: { ...this.seller },
          ...this.signUpForm.value,
        },
      },
    };

    if (this._userService._currentUser) {
      dataVendor.data.sender = this._userService._currentUser.id;
    }

    this._formService.sendMessageToVendor(dataVendor).subscribe(
      () => {
        // Re-enable the form
        this.signUpForm.enable();

        // Reset the form
        this.signUpNgForm.resetForm();

        // Set the alert
        this.alert = {
          type: 'success',
          message: 'Obrigado! Sua mensagem foi enviada com sucesso.',
        };

        // Show the alert
        this.showAlert = true;

        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      },
      (response) => {
        // Re-enable the form
        this.signUpForm.enable();

        // Reset the form
        this.signUpNgForm.resetForm();

        // Set the alert
        this.alert = {
          type: 'error',
          message: 'Something went wrong',
        };

        // Show the alert
        this.showAlert = true;
      },
    );
  }
}
