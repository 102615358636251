import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@core/icons/icons.module';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';
import { ImageComponent } from './image.component';

@NgModule({
  declarations: [ImageComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoCoreModule,
    MatIconModule,
    IconsModule,
  ],
  exports: [ImageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageModule {}
