<form class="flex w-full py-8 mb-8 bg-gray10-500" [formGroup]="filterForm">
  <div class="max-w-screen-xl mx-auto px-4 md:px-14 w-full">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-full gap-6">
      <ng-container *ngFor="let item of filterFields">

        <ng-container *ngIf="item.values.length >0">
          <div class="filter__wrapper filter-option flex flex-col w-full"
            [ngClass]="{'col-span-1': item.type==='range','sm:col-span-2': item.type==='range'}">

            <ng-container [ngSwitch]="item.type">

              <ng-container *ngSwitchCase="'range'">
                <div class="flex flex-col sm:flex-row justify-between w-full items-center gap-4"
                  formGroupName="{{item.key}}">
                  <mat-form-field class="w-full">
                    <mat-label class="filter-option__name">{{ item.label }} {{'Minimum' | transloco}}</mat-label>
                    <mat-select class="filter-option__select" [formControlName]="item.key_min">
                      <mat-option>{{'All' | transloco}}</mat-option>
                      <mat-option [value]="topping.value" *ngFor="let topping of item.values">
                        <ng-container *ngIf="item.is_currency">
                          {{topping.value | currency:'EUR':'symbol':'1.2-2'}}
                        </ng-container>
                        <ng-container *ngIf="!item.is_currency">
                          {{topping.value}} ({{topping.count}})
                        </ng-container>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-label class="filter-option__name">{{item.rangeLabel}}</mat-label>
                  <mat-form-field class="w-full">
                    <mat-label class="filter-option__name">{{ item.label }} {{'Maximum' | transloco}}</mat-label>
                    <mat-select class="filter-option__select" [formControlName]="item.key_Max">
                      <mat-option>{{'All' | transloco}}</mat-option>
                      <mat-option [value]="topping.value" *ngFor="let topping of item.values">
                        <ng-container *ngIf="item.is_currency">
                          {{topping.value | currency:'EUR':'symbol':'1.2-2'}}
                        </ng-container>
                        <ng-container *ngIf="!item.is_currency">
                          {{topping.value}} ({{topping.count}})
                        </ng-container>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <div class="flex flex-wrap">
                  <mat-form-field class="w-full">
                    <mat-label class="filter-option__name">{{ item.label }}</mat-label>
                    <mat-select class="filter-option__select" [formControlName]="item.key" multiple>
                      <mat-option [value]="topping.value" *ngFor="let topping of item.values">{{topping.value}}
                        ({{topping.count}})</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>