import { Component, Input, OnInit } from '@angular/core';
import { WidgetBaseComponent } from '@shared/widgets/widget-base/widget-base.component';

@Component({
  selector: 'classified-card',
  templateUrl: './classified-card.component.html',
  styleUrls: ['./classified-card.component.scss'],
})
export class ClassifiedCardComponent
  extends WidgetBaseComponent
  implements OnInit
{
  @Input()
  item: any;

  constructor() {
    super();
  }

  ngOnInit() {
    console.log('ArticleCardComponent constructor', this.item);
  }
}
