import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { MarketplaceService } from 'app/pages/marketplace/marketplace.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

declare let Isotope: any;
declare let Packery: any;

@Component({
  selector: 'widget-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent
  extends WidgetBaseComponent
  implements OnInit, AfterViewInit
{
  @Input() public items: any;

  isogrid: any;

  isScreenSmall: boolean;
  _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private service: MarketplaceService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.widget?.items) {
      this.widget.items = [];
    }

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  ngAfterViewInit(): void {
    if (this.widget.Layout === 'Masonry') {
      this.execIsotope().subscribe((iso) => {
        if (iso) {
          iso.arrange({
            sortBy: 'original-order',
            gutter: +this.widget?.spaceBetween,
          });

          iso.layout();
        }
        if (isPlatformBrowser(this.platformId)) {
          window.dispatchEvent(new Event('resize'));
        }
      });
    }
  }

  getFullColumns(full) {
    return full && this.widget?.Columns
      ? 'col-span-' + this.widget?.Columns
      : '';
  }

  execIsotope(): Observable<any> {
    return new Observable((observer) => {
      setTimeout(() => {
        if (isPlatformBrowser(this.platformId)) {
          const elem = document.querySelector('.grid' + this.widget?.id);
          // const iso = new Packery(elem, {
          //   itemSelector: '.grid-item',
          //   gutter: +this.widget?.spaceBetween,
          // });
          this.isogrid = new Isotope(elem, {
            itemSelector: '.grid-item',
            layoutMode: 'packery',
            packery: {
              gutter: +this.widget?.spaceBetween,
            },
          });
          observer.next(this.isogrid);
          observer.complete();
        }
      }, 500);
    });
  }

  onImageLoad(e: any): void {
    if (e?.status === 'loaded') {
      if (this.isogrid) {
        this.isogrid.arrange({
          sortBy: 'original-order',
          gutter: +this.widget?.spaceBetween,
        });
        this.isogrid.layout();
      }
    }
  }
}
