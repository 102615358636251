// -----------------------------------------------------------------------------------------------------
// @ STORAGE UTILITIES
//
// Methods are derivations of the Localstorage helper service methods with encripation
// -----------------------------------------------------------------------------------------------------

import { Inject, PLATFORM_ID } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';

export class Storage {
  private static _passPhrase = environment.passphrase;

  /**
   * Constructor
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  private static storageEnviorment =
    typeof localStorage !== 'undefined' && localStorage
      ? !environment.production
        ? localStorage
        : localStorage
      : {
          _data: {},
          setItem: (id, val) =>
            (Storage.storageEnviorment._data[id] = String(val)),
          getItem: (id) =>
            Storage.storageEnviorment._data.hasOwnProperty(id)
              ? Storage.storageEnviorment._data[id]
              : undefined,
          removeItem: (id) => delete Storage.storageEnviorment._data[id],
          clear: () => (Storage.storageEnviorment._data = {}),
        };

  public static encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, Storage._passPhrase).toString();
  }

  public static _decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, Storage._passPhrase).toString(
      CryptoJS.enc.Utf8,
    );
  }

  static set(key: string, value: string) {
    Storage.storageEnviorment.setItem(key, Storage.encrypt(value));
  }

  static get(key: string): string {
    if (Storage.storageEnviorment.getItem(key)) {
      return Storage._decrypt(Storage.storageEnviorment.getItem(key));
    }

    return null;
  }

  static remove(key: string) {
    Storage.storageEnviorment.removeItem(key);
  }

  static clear() {
    Storage.storageEnviorment.clear();
  }
}
