import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MailchimpService {
  private _mailchimpApiKey: string;
  private _mailchimpListId: string;
  private _mailchimpAudienceId: string;
  private _mailchimpServer: string;

  set serverKey(value: string) {
    this._mailchimpServer = value;
  }

  set apiKey(value: string) {
    this._mailchimpApiKey = value;
  }

  set listId(value: string) {
    this._mailchimpListId = value;
  }

  set audienceId(value: string) {
    this._mailchimpAudienceId = value;
  }

  async addNewMember(params: any) {
    // mailchimp.setConfig({
    //   apiKey: this._mailchimpApiKey,
    //   server: this._mailchimpServer,
    // });

    // const result = mailchimp.lists.addListMember(this._mailchimpListId, {
    //   email_address: params?.email ?? params,
    //   status: 'subscribed',
    // });

    return true;
  }
}
