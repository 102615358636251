export const environment = {
  production: true,
  graphql: 'https://app.motorbest.pt/graphql',
  api: 'https://app.motorbest.pt/api',
  base: 'https://motorbest.pt/',
  assets: 'https://app.motorbest.pt',
  solr: 'https://motorbest-solr.targx.com/api',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE: 'mtb-access_token',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE_COMPANY: 'mtb-company',

  passphrase:
    'Obedient Cool Feel Nature Patience Separation Shoot Theater Mud Committee 5',
  aeskey: 'XKviu4Pqhn@NcRfUjXnr6slsXSet!!8vGiC$$j9yPdSgVk/Ypr6slsXSet8vGi',
  enableMarketplace: false,
};
