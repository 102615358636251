<div class="widget-author__content flex">
  <div class="widget-author__image" *ngIf="widget?.config.ShowImage">
    <ng-container *ngIf="widget?.author?.data?.attributes.photo">
      <img [src]="getImageUrl(widget?.author?.data?.attributes.photo?.data, false, 'medium')" alt=""
        class="object-contain" loading="lazy" />
    </ng-container>
    <ng-container *ngIf="!widget?.author?.data?.attributes.photo">
      <div class="rounded-full w-18 h-18 border border-primary bg-slate-200">
        {{ widget?.author?.data?.attributes.firstName.charAt(0) }}
      </div>
    </ng-container>
  </div>
  <div class="widget-author__info">
    <div class="widget-author__info--name">
      {{ widget?.author?.data?.attributes.firstName }}
      {{ widget?.author?.data?.attributes.lastName }}
    </div>
    <div class="widget-author__info--date">{{ widget?.date | date }}</div>
  </div>
</div>