import { Component, Input } from '@angular/core';
import { WidgetBaseComponent } from '@shared/widgets/widget-base/widget-base.component';

@Component({
  selector: 'widget-news-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent extends WidgetBaseComponent {
  @Input()
  showDate: boolean;

  @Input()
  showAuthor: boolean;

  @Input()
  showImage: boolean;

  @Input()
  item: any;
}
