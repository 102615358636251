import { Component } from '@angular/core';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'widget-article-block-author',
  templateUrl: './block-author.component.html',
  styleUrls: ['./block-author.component.scss'],
})
export class BlockAuthorComponent extends WidgetBaseComponent {
  constructor() {
    super();
  }
}
