import { Component, Input } from '@angular/core';

@Component({
  selector: 'tx-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class ColumnComponent {
  @Input() widgets: any[];
  @Input() classes: string;
}
