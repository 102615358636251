import { Injectable } from '@angular/core';
import { LocalesService } from '@core/transloco/locales.service';
import { ApiService } from '@targx/services/api/api.service';
import qs from 'qs';
import { Observable, ReplaySubject, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  private _articles: any;
  private _article: any;

  public onArticlesChanged: ReplaySubject<any> = new ReplaySubject<any>(1);
  public onArticleChanged: ReplaySubject<any> = new ReplaySubject<any>(1);

  public onArticlesHomeChanged: ReplaySubject<any> = new ReplaySubject<any>(1);
  public onArticleCategoryChanged: ReplaySubject<any> = new ReplaySubject<any>(
    1,
  );

  public locale = { code: 'pt-PT' };

  constructor(
    private api: ApiService,
    private localeService: LocalesService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for footer
   */
  get articles$(): Observable<any> {
    return this.onArticlesChanged.asObservable();
  }

  /**
   * Getter for footer
   */
  get article$(): Observable<any> {
    return this.onArticleChanged.asObservable();
  }

  /**
   * Getter for footer
   */
  get articlesHome$(): Observable<any> {
    return this.onArticlesHomeChanged.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // filters[article_categories][slug][$contains]=historia
  // {"article_categories":{"slug":{"$contains":"historia"}}}
  // sort=createdAt:desc&pagination[page]=0&pagination[pageSize]=5
  // -----------------------------------------------------------------------------------------------------
  getPage(): Observable<any> {
    // const qs = [
    //   'locale=pt-PT',
    //   'populate[0]=*',
    //   'populate[columnA][populate]=*',
    //   'populate[columnB][populate]=*',
    //   'populate[columnC][populate]=*',
    //   'populate[columnD][populate]=*',
    // ];
    this.locale = this.localeService.getActiveLocale();
    const data = {
      locale: this.locale.code,
      'populate[0]': '*',
      'populate[Banner][populate]': '*',
      'populate[SEO][populate]': '*',
    };
    return this.api.get(`/page-article`, data).pipe(
      tap((content) => {
        this.onArticlesHomeChanged.next(content);
      }),
    );
  }

  getCategory(field = 'slug', query = ''): Observable<any> {
    if (!query) {
      this.onArticleCategoryChanged.next(null);
      return of(null);
    }
    this.locale = this.localeService.getActiveLocale();
    const data = {
      locale: this.locale.code,
      'populate[0]': '*',
      'populate[SEO][populate]': '*',
    };
    if (query) {
      data[`filters[${field}][$containsi]`] = query;
    }
    return this.api.get(`/article-categories`, data).pipe(
      tap((content) => {
        this.onArticleCategoryChanged.next(content);
      }),
    );
  }

  getAll(
    field = 'slug',
    query = '',
    page = 0,
    limit = 100,
    sort = 'publishDate:desc',
    search?: string,
  ): Observable<any> {
    this.locale = this.localeService.getActiveLocale();

    const data: any = {
      locale: this.locale.code,
      populate: '*',
      'pagination[page]': page,
      'pagination[pageSize]': limit,
      sort: sort,
    };

    if (query) {
      data[`filters[article_categories][${field}][$containsi]`] = query;
    }

    if (search) {
      const split = search.trim().split(' ');
      split.forEach((element, index) => {
        data[`filters[$or][${index}][title][$containsi]`] = element;
      });

      //data['filters[title][$containsi]'] = search;
    }

    return this.api.get(`/articles`, data).pipe(
      tap((footer) => {
        this.onArticlesChanged.next(footer);
      }),
    );
  }

  get(field = 'slug', query = ''): Observable<any> {
    this.locale = this.localeService.getActiveLocale();
    const data: any = {
      locale: this.locale.code,
      'populate[0]': '*',
      'populate[SEO][populate]': '*',
      'populate[layout][populate][0]': '*',
      'populate[layout][populate][formulario][populate][0]': '*',
      'populate[layout][populate][formulario][populate][Options][populate]':
        '*',
      'populate[layout][populate][formulario][populate][Colunas][populate]':
        '*',
      'populate[layout][populate][formulario][populate][Fields][populate]': '*',
      'populate[layout][populate][image][populate]': '*',
      'populate[layout][populate][assets][populate]': '*',
      'populate[layout][populate][url][populate]': '*',
      'populate[layout][populate][Columns][populate]': '*',
      'populate[layout][populate][Colunas][populate]': '*',
      'populate[layout][populate][Options][populate]': '*',
      'populate[layout][populate][Responsive][populate]': '*',
      'populate[layout][populate][aligment][populate]': '*',
      'populate[layout][populate][caption][populate]': '*',
      'populate[banners][populate]': '*',
      'populate[article_categories][populate]': '*',
      'populate[article_tags][populate]': '*',
      'populate[author][populate][photo][populate]': '*',
      'populate[comments][populate]': '*',
      'populate[featureImage][populate]': '*',
    };

    // if (query) {
    //   data[`filters[${field}][$contains]`] = query;
    // }

    return this.api.get(`/articles/${query}`, data).pipe(
      tap((footer) => {
        this.onArticleChanged.next(footer);
      }),
    );
  }

  getNewsForWidget(
    query: any = {},
    page = 0,
    limit = 24,
    sort = 'publishDate:desc',
  ): Observable<any> {
    this.locale = this.localeService.getActiveLocale();
    const data: any = {
      locale: this.locale.code,
      populate: '*',
      'pagination[page]': page,
      'pagination[pageSize]': limit,
      sort: sort,
    };

    if (query?.numberOfArticles) {
      data['pagination[pageSize]'] = query.numberOfArticles;
    }

    if (query?.skipNumberOfArticles) {
      data['pagination[pageSize]'] =
        query.numberOfArticles + query?.skipNumberOfArticles;
    }

    if (query?.notIn) {
      data.filters = {
        ...data.filters,
        id: {
          $notIn: [query.notIn],
        },
      };
    }

    if (query.article_categories) {
      data.filters = {
        ...data.filters,
        $or: query.article_categories?.data?.map((category: any) => {
          return {
            article_categories: {
              slug: {
                $contains: category.attributes.slug,
              },
            },
          };
        }),
      };
    }

    if (query.orderField) {
      //data.sort = `${query.orderField}:${query.orderValue}`;
    }

    const dataQuery = qs.stringify(data, {
      encodeValuesOnly: true,
    });

    return this.api.get(`/articles`, '', '?' + dataQuery, false).pipe(
      map((res: any) => {
        res.data = res.data.slice(query?.skipNumberOfArticles ?? 0);
        return res;
      }),
    );
  }
}
