import { DOCUMENT } from '@angular/common';
import { inject, Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FuseUtilsService } from '@fuse/services/utils';
import { environment } from 'environments/environment';
import { IParam, RES, SeoConfig, toFormat } from './seo.config';

/**
 * https://stackblitz.com/edit/angular-seo-service?file=src%2Fapp%2Fconfig.ts,src%2Fapp%2Fservices%2Fseo.service.ts,src%2Fapp%2Fservices%2Fmodels.ts,src%2Fapp%2Fservices%2Fhome.seo.service.ts
 */

interface TXMetaTag {
  name?: string;
  property?: string;
  content?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private _jsonSnippet: HTMLScriptElement;
  private _graphObjects: any[] = [];

  private _canonicalLink: HTMLLinkElement;
  private _alternateLinks: HTMLLinkElement[] = [];
  private fuseUtilsService = inject(FuseUtilsService);

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc: Document,
  ) {
    // ad fixed tags
    this.AddTags();
  }

  get url(): string {
    let url = this.doc.location.pathname;
    if (url.indexOf(';') > -1) {
      url = url.substring(0, url.indexOf(';'));
    }
    return url;
  }

  get defaultUrl(): string {
    return toFormat(
      SeoConfig.Seo.baseUrl,
      SeoConfig.Seo.defaultRegion,
      SeoConfig.Seo.defaultLanguage,
      '',
    );
  }
  get siteUrl(): string {
    return toFormat(
      SeoConfig.Seo.baseUrl,
      SeoConfig.Basic.region,
      SeoConfig.Basic.language,
      '',
    );
  }

  AddTags() {
    // get fixed tags and add, always check since we have inhertited services

    // add tags
    this.meta.addTags(SeoConfig.Seo.tags);

    // add canonical link
    const _canonical = this.doc.querySelector('link[rel="canonical"]');
    this._canonicalLink =
      (_canonical as HTMLLinkElement) || this.createCanonicalLink();

    // add alternate language, one at a time, here
    const _links = this.doc.querySelectorAll('link[rel="alternate"]');
    if (_links.length > 0) {
      this._alternateLinks = Array.from(_links) as HTMLLinkElement[];
    } else {
      this._alternateLinks = SeoConfig.Seo.hrefLangs.map((n) =>
        this.createAlternateLink(),
      );
    }

    // create the initial ld+json script
    this._jsonSnippet =
      this.doc.querySelector('script[type="application/ld+json"]') ||
      this.createJsonSnippet();
  }

  /**
   * Public methods
   */
  setPage(title?: string) {
    // set to title if found, else fall back to default
    this.setTitle(title || RES.SITE_NAME);

    // aslo reset canonical
    this.setUrl();
  }

  handleSeoFromApi(data: any) {
    this.setPage();

    // set title
    if (data?.metaTitle) this.setTitle(data.metaTitle);

    // set description
    if (data?.metaDescription) this.setDescription(data.metaDescription);

    // set image
    //this.setImage(data.image);

    // empty first
    this.emptyJsonSnippet();

    if (data?.structuredData) {
      data?.structuredData['@graph'].forEach((n) => {
        this.updateJsonSnippet(n);
      });
    }
  }

  setTag(tag: any) {
    this.meta.updateTag(tag);
  }
  /**
   * Private methods
   */
  private createAlternateLink(): HTMLLinkElement {
    // append alternate link to body
    const _link = this.doc.createElement('link');
    _link.setAttribute('rel', 'alternate');
    this.doc.head.appendChild(_link);
    return _link;
  }

  private createCanonicalLink(): HTMLLinkElement {
    // append canonical to body
    const _canonicalLink = this.doc.createElement('link');
    _canonicalLink.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(_canonicalLink);

    return _canonicalLink;
  }

  private createJsonSnippet(): HTMLScriptElement {
    // if on browser platform, return

    const _script = this.doc.createElement('script');
    // set attribute to application/ld+json
    _script.setAttribute('type', 'application/ld+json');

    // append and return reference
    this.doc.head.appendChild(_script);
    return _script;
  }

  /*** the protected functions ***/
  protected setUrl(slug = '', params?: IParam[]) {
    // prefix with baseUrl and remove language, but not in development
    const path = this.doc.location.pathname.substring(
      environment.production ? 4 : 4,
    );

    let url = this.defaultUrl + slug;

    if (url.indexOf(';') > -1) {
      url = url.substring(0, url.indexOf(';'));
    }
    // if category or page exist, append them as query params
    // or matrix params, that should be okay, but not wise
    if (params) {
      const s = new URLSearchParams();
      params.forEach((p) => s.append(p.name, p.value));
      url += '?' + s.toString();
    }

    // set attribute and og:url
    this._canonicalLink.setAttribute('href', url);
    this.meta.updateTag({ property: 'og:url', content: url });

    this.setAlternateLinks(path);
  }

  protected setAlternateLinks(path) {
    // for each config hrefLang, set the link that already exists

    SeoConfig.Seo.hrefLangs.forEach((n, i) => {
      // what is the right language
      let lang = n.language;
      if (lang === 'x-default') lang = SeoConfig.Seo.defaultLanguage;

      // construct the url
      const url = toFormat(
        SeoConfig.Seo.baseUrl,
        SeoConfig.Seo.defaultRegion,
        lang,
        path,
      );

      // construct hreflang
      const hreflang = n.language;
      this._alternateLinks[i].setAttribute('href', url);
      this._alternateLinks[i].setAttribute('hreflang', hreflang);
    });
  }

  protected setTitle(title: string) {
    //const _title = `${title} - ${RES.SITE_NAME}`;
    const _title = `${title} - ${RES.SITE_NAME}`;

    this.title.setTitle(_title);
    const _ogtitle = title.slice(0, 60);
    this.meta.updateTag({
      name: 'title',
      property: 'og:title',
      content: _ogtitle,
    });
    this.meta.updateTag({ property: 'twitter:title', content: _ogtitle });
  }
  protected setDescription(description: string) {
    const _description = this.fuseUtilsService.removeHTMLTags(description);
    this.meta.updateTag({
      name: 'description',
      content: `${_description} - ${RES.SITE_NAME}`,
    });

    const _ogdescription = _description.slice(0, 160);
    this.meta.updateTag({
      property: 'og:description',
      content: _ogdescription,
    });
  }

  protected setImage(imageUrl?: string) {
    // prepare image, either passed or
    const _imageUrl = imageUrl || SeoConfig.Seo.defaultImage;

    this.meta.updateTag({
      name: 'image',
      property: 'og:image',
      content: _imageUrl,
    });
    this.meta.updateTag({ property: 'twitter:image', content: _imageUrl });
  }

  protected updateJsonSnippet(schema: any) {
    // if on briwser platform return

    // first find the graph objects then append to it
    const found = this._graphObjects.findIndex(
      (n) => n['@type'] === schema['@type'],
    );
    if (found > -1) {
      this._graphObjects[found] = schema;
    } else {
      this._graphObjects.push(schema);
    }

    const _graph = {
      '@context': 'https://schema.org',
      '@graph': this._graphObjects,
    };
    this._jsonSnippet.textContent = JSON.stringify(_graph);
  }

  protected emptyJsonSnippet() {
    // sometimes, in browser platform, we need to empty objects first
    this._graphObjects = [];
  }
}
