export const SeoConfig = {
  Basic: {
    // from environment or fed by server
    language: 'pt',
    region: 'PT',
  },
  Seo: {
    tags: [
      { property: 'og:site_name', content: 'Motorbest' },
      { property: 'og:type', content: 'website' },
      { property: 'twitter:site', content: '@motorbest' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:creator', content: '@motorbest' },
      { name: 'author', content: 'TargX' },
      { name: 'generator', content: 'TX Content Manager' },
    ],
    defaultImage: '/assets/images/logo/logo-1.png',
    logoUrl: '/assets/images/logo/logo-1.png',
    baseUrl: 'https://$0.motorbest.pt/$1/$2',
    defaultLanguage: 'pt',
    defaultRegion: 'www',
    hrefLangs: [
      // { region: 'www', language: 'pt' },
      // { region: 'www', language: 'en' },
      { language: 'en' },
      { language: 'pt' },
      // { language: 'x-default' },
    ],
  },
};

export const RES = {
  SITE_NAME: 'Motorbest.pt',
};

export const toFormat = (s: string, ...args: any) => {
  const regExp = /\$(\d+)/gi;
  // match $1 $2 ...
  return s.replace(regExp, (match, index) => {
    return args[index] ?? match;
  });
};

// search list params model
export interface IParam {
  name: string;
  value: string;
}
