<ng-container *ngIf="footer">
  <footer class="tx-footer__wrapper">
    <tx-newsletter></tx-newsletter>
    <div
      class="tx-footer__content flex justify-center w-full py-14 bg-black-motorbest-500"
    >
      <div
        class="tx-footer__wrapepr--inner max-w-screen-xl w-full px-14 grid grid-cols-1 gap-8 md:gap-18 md:grid-cols-4 sm:grid-cols-2"
      >
        <tx-column
          classes="tx-footer__column tx-footer__column--first col-span-1"
          [widgets]="footer.data.attributes.columnA"
        ></tx-column>
        <tx-column
          classes="tx-footer__column col-span-1"
          [widgets]="footer.data.attributes.columnB"
        ></tx-column>
        <tx-column
          classes="tx-footer__column col-span-1"
          [widgets]="footer.data.attributes.columnC"
        ></tx-column>
        <tx-column
          classes="tx-footer__column tx-footer__column--last col-span-1"
          [widgets]="footer.data.attributes.columnD"
        ></tx-column>
      </div>
    </div>

    <div class="tx-footer__bottom bg-black py-4 flex justify-center">
      <div
        class="tx-footer__bottom--inner max-w-screen-xl w-full px-14 flex justify-center items-center"
      >
        <div class="text-sm text-gray30-500">
          {{ "All rights reserved" | transloco }}
        </div>
        <div class="text-sm text-gray30-500 ml-4">
          Engineered by
          <a
            href="https://www.targx.com/?utm_source=motorbest.pt"
            target="_blank"
            class="underline"
            >Targx</a
          >
        </div>
      </div>
    </div>
  </footer>
</ng-container>

<footer class="tx-footer__wrapper" *ngIf="false">
  <div
    class="tx-footer__top flex justify-center w-full pt-12 pb-14 bg-gray60-500"
  >
    <div
      class="tx-footer__top--inner max-w-screen-xl w-full px-14 grid grid-cols-1 gap-7 md:grid-cols-9"
    >
      <div class="col-span-1 hidden md:flex"></div>
      <div class="col-span-4 mr-30">
        <h4 class="text-gray10-500 mb-6">
          {{ "Subscribe to our newsletter" | transloco }}
        </h4>
        <form action="" [formGroup]="newsletterForm" class="flex">
          <!-- Email field -->
          <mat-form-field class="w-full">
            <input
              id="email"
              matInput
              [formControlName]="'email'"
              placeholder="Email"
              type="email"
            />
          </mat-form-field>
          <button
            mat-flat-button
            color="primary"
            class="ml-4 rounded-none border-0 max-h-16 flex w-36 max-w-max bg-primary-500"
            style="height: 50px !important"
          >
            {{ "Subscribe" | transloco }}
          </button>
        </form>
      </div>

      <div class="col-span-3">
        <h4 class="text-gray10-500 mb-6">
          {{ "Follow us on social media" | transloco }}
        </h4>
        <div class="footer__social">
          <a href="#" class="footer__social--item">
            <mat-icon svgIcon="feather:facebook"></mat-icon>
          </a>
          <a href="#" class="footer__social--item">
            <mat-icon svgIcon="feather:instagram"></mat-icon>
          </a>
        </div>
      </div>
      <div class="col-span-1 hidden md:flex"></div>
    </div>
  </div>
  <div
    class="tx-footer__content flex justify-center w-full py-14 bg-black-motorbest-500"
  >
    <div
      class="tx-footer__wrapepr--inner max-w-screen-xl w-full px-14 grid grid-cols-1 gap-8 md:gap-18 md:grid-cols-4 sm:grid-cols-2"
    >
      <div class="tx-footer__column tx-footer__column--first col-span-1">
        <div class="tx-footer__logo mb-6">
          <a [routerLink]="['/']">
            <img
              src="assets/images/logo/logo-1-white.png"
              alt="{{ 'Motorbest' | transloco }}"
              class="max-h-14 w-auto"
              loading="lazy"
            />
          </a>
        </div>
        <div class="tx-footer__description text-gray30-500">
          <div class="text-sm leading-base mb-4">
            <strong>Motorbest</strong> é uma marca dedicada à criação de
            conteúdos automóveis: notícias, histórias, opiniões, filmes e
            imagens, com especial foco nos veículos desportivos, clássicos e
            coleccionáveis.
          </div>
          <div class="text-sm leading-base mb-4">
            <strong>Motorbest</strong> é também uma marca de serviços de
            consultoria de comunicação no ramo automóvel.
          </div>
          <div class="text-sm leading-base mb-4">
            <strong>Motorbest</strong> é ainda uma marca de produtos “lifestyle”
            dedicados a entusiastas dos motores.
          </div>
        </div>
      </div>
      <div class="tx-footer__column col-span-1">
        <div class="tx-footer__column--block mb-8">
          <p class="text-md text-gray10-500 font-bold mb-4">
            Junte-se à equipa
          </p>
          <div class="text-sm leading-base text-gray30-500">
            Somos uma comunidade viva e em constante transformação. Se tem uma
            ou muitas histórias para partilhar, se tem uma vontade incontrolável
            de escrever sobre motores, se deseja partilhar fotos ou
            experiências, suba a bordo. O mais certo é haver lugar para mais um.
          </div>
        </div>
        <div class="tx-footer__column--block">
          <p class="text-md text-gray10-500 font-bold mb-4">
            Vender em Motorbest
          </p>
          <div class="text-sm leading-base text-gray30-500">
            A loja Motorbest é um espaço colaborativo. Tem uma ideia de produto?
            Tem uma marca própria? Fale connosco. Promovemos parcerias,
            disponibilizamos a presença online, colaboramos na comunicação e
            promoção e disponibilizamos meios para produzir e distribuir os seus
            produtos com qualidade e competitividade.
          </div>
        </div>
      </div>
      <div class="tx-footer__column col-span-1">
        <div class="tx-footer__column--block">
          <p class="text-md text-gray10-500 font-bold mb-4">
            Vender em Motorbest
          </p>
          <div class="text-sm leading-base text-gray30-500">
            A loja Motorbest é um espaço colaborativo. Tem uma ideia de produto?
            Tem uma marca própria? Fale connosco. Promovemos parcerias,
            disponibilizamos a presença online, colaboramos na comunicação e
            promoção e disponibilizamos meios para produzir e distribuir os seus
            produtos com qualidade e competitividade.
          </div>
        </div>
      </div>
      <div class="tx-footer__column tx-footer__column--last col-span-1">
        <div class="tx-footer__column--block mb-8">
          <p class="text-md text-gray10-500 font-bold mb-4">
            Vender em Motorbest
          </p>
          <div class="text-sm leading-base text-gray30-500">
            <a href="mailto:geral@motorbest.pt">geral&#64;motorbest.pt</a>
          </div>
        </div>

        <div class="tx-footer__column--block mb-8">
          <a href="#" class="text-md text-gray10-500 font-bold mb-4 underline"
            >Política de privacidade</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="tx-footer__bottom bg-black py-4 flex justify-center">
    <div
      class="tx-footer__bottom--inner max-w-screen-xl w-full px-14 flex justify-center items-center"
    >
      <div class="text-sm text-gray30-500">
        {{ "© 2024 Motorbest. Todos os direitos reservados." | transloco }}
      </div>
      <div class="text-sm text-gray30-500 ml-4">
        Engineered by
        <a
          href="https://www.targx.com/?utm_source=motorbest.pt"
          target="_blank"
          class="underline"
          >Targx</a
        >
      </div>
    </div>
  </div>
</footer>
