import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@core/navigation/navigation.service';
import { Navigation } from '@core/navigation/navigation.types';
import { SettingsService } from '@core/settings/settings.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoService } from '@ngneat/transloco';
import { ArticlesService } from 'app/pages/articles/articles.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'tx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: fuseAnimations,
})
export class HeaderComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;

  isSticky = false;

  openNavigation = false;

  menuOpen: number;

  public translocoSevice: any = inject(TranslocoService);
  public activeLang: string = this.translocoSevice.getActiveLang();

  public showLogin = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _router: Router,
    private route: ActivatedRoute,
    private articleService: ArticlesService,
    private _settings: SettingsService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    if (window?.scrollY > 72) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this._router.events.subscribe((event) => {
      this.openNavigation = false;
      this.menuOpen = undefined;
    });

    this._settings.settings$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.showLogin =
          settings.data.attributes.SettingField.find(
            (setting) => setting.name === 'showLogin',
          ).fieldValue == 1;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // // Get the navigation
    // const navigation =
    //   this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
    //     name,
    //   );

    // if (navigation) {
    //   // Toggle the opened status
    //   navigation.toggle();
    // }

    if (this.isScreenSmall) {
      this.openNavigation = !this.openNavigation;
    }
  }

  onSearchEvent(event: any): void {
    this.articleService
      .getAll('slug', '', 0, 24, 'publishDate:desc', event)
      .subscribe();
    this._router.navigate(['/', this.activeLang, 'artigos'], {
      queryParams: { s: event },
      relativeTo: this.route,
    });
  }

  log(n) {
    console.log(n);
  }

  toggleMenu(item) {
    if (this.menuOpen === item?.id) {
      return (this.menuOpen = undefined);
    }

    return (this.menuOpen = item?.id ?? undefined);
  }
}
