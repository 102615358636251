import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ApiService } from '@targx/services/api/api.service';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalesService {
  private _locales$: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _locales: any;

  constructor(
    private api: ApiService,
    private transloco: TranslocoService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get locales$() {
    return this._locales$.asObservable();
  }

  get locales() {
    return this._locales;
  }

  set locales(value) {
    this._locales = value;
    this._locales$.next(value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  get(): Observable<any> {
    const data = {};

    return this.api.get(`/i18n/locales`, data).pipe(
      tap((footer) => {
        this.locales = footer;
      }),
    );
  }

  getActiveLocale() {
    const locale = this.transloco.getActiveLang();
    return (
      this.locales.find((l) => l.code.split('-')[0] === locale) ?? {
        code: 'pt-PT',
      }
    );
  }
}
