import { Component, Input, OnInit } from '@angular/core';
import { MarketplaceService } from 'app/pages/marketplace/marketplace.service';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'widget-product-group',
  templateUrl: './product-group.component.html',
  styleUrls: ['./product-group.component.scss'],
})
export class ProductGroupComponent
  extends WidgetBaseComponent
  implements OnInit
{
  @Input() public items: any;
  @Input() public adsPage: any;
  @Input() public adsPosition: any;

  constructor(private service: MarketplaceService) {
    super();
  }

  ngOnInit(): void {
    if (!this.widget?.items) {
      this.widget.items = [];
      this.service.getProductsForWidget(this.widget).subscribe((res) => {
        this.widget.items = res;
      });
    }
  }
}
