<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div class="flex flex-col flex-auto w-full h-full">
  <tx-header></tx-header>

  <main class="flex flex-col flex-auto justify-start pt-14 md:pt-18">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                         Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </main>

  <tx-footer></tx-footer>
</div>
