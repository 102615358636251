<figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
  <a [href]="iBigSrc + '?t=' + fuseUtilsService.randomId()" itemprop="contentUrl" (click)="onClick($event)"
    *ngIf="!href" class="lighbox-image" [id]="fuseUtilsService.randomId()">
    <img #thumbEl [src]="iSrc" [loading]="imageLazy" />
  </a>
  <a [routerLink]="[href]" (click)="onClick($event)" *ngIf="href && (!target || target != '_blank')">
    <img #thumbEl [src]="iSrc" itemprop="contentUrl" [loading]="imageLazy" />
  </a>
  <a [href]="href" *ngIf="href && target && target == '_blank'" [target]="_blank">
    <img #thumbEl [src]="iSrc" itemprop="contentUrl" [loading]="imageLazy" />
  </a>
  <figcaption itemprop="caption description">{{ caption }}</figcaption>
</figure>