import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { ArticlesService } from 'app/pages/articles/articles.service';
import { takeUntil } from 'rxjs';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { NewsLayout } from './news-layout';

@Component({
  selector: 'widget-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent extends WidgetBaseComponent implements OnInit {
  public layoutFormat = NewsLayout;

  @Input() public items: any;
  @Input() public adsPosition: any;
  @Input() public adsPage: any;
  @Input() public showSeeAllLink = true;
  @Input() public showPagination = false;
  @Input() public showListTitle = true;

  isScreenSmall: boolean;

  newsLatestSlug = '';

  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private service: ArticlesService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  @HostBinding('style.backgroundColor') backgroundColor = 'transparent';

  ngOnInit(): void {
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    if (!this.widget?.items) {
      this.widget.items = [];
      this.service.getNewsForWidget(this.widget).subscribe((res) => {
        this.widget.items = res.data;

        this.cd.markForCheck();
      });
    }

    if (this.widget?.article_categories?.data?.length === 1) {
      this.newsLatestSlug =
        this.widget?.article_categories.data[0]?.attributes?.slug;
    }

    if (this.widget?.hasBackgroundColor) {
      this.backgroundColor = this.widget.backgroundColor;
    }
    this.showSeeAllLink = this.widget?.showSeeAllLink ?? true;
  }
}
