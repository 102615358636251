import { Injectable } from '@angular/core';
import { LocalesService } from '@core/transloco/locales.service';
import { ApiService } from '@targx/services/api/api.service';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  public locale = { code: 'pt-PT' };

  private _footer: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private api: ApiService,
    private localeService: LocalesService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for footer
   */
  get footer$(): Observable<any> {
    return this._footer.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  get(): Observable<any> {
    this.locale = this.localeService.getActiveLocale();
    const qs = [
      'locale=' + this.locale.code,
      'populate[0]=*',
      'populate[columnA][populate]=*',
      'populate[columnB][populate]=*',
      'populate[columnC][populate]=*',
      'populate[columnD][populate]=*',
    ];
    return this.api.get(`/footer?${qs.join('&')}`).pipe(
      tap((footer) => {
        this._footer.next(footer);
      }),
    );
  }
}
