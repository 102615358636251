import { Injectable } from '@angular/core';
import { ApiService } from '@targx/services/api/api.service';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaidMediaService {
  private _items: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private api: ApiService) {}

  get items$(): Observable<any> {
    return this._items.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  get(): Observable<any> {
    // const qs = [
    //   'locale=pt-PT',
    //   'populate[0]=*',
    //   'populate[columnA][populate]=*',
    //   'populate[columnB][populate]=*',
    //   'populate[columnC][populate]=*',
    //   'populate[columnD][populate]=*',
    // ];
    const data = {
      locale: 'pt-PT',
      populate: '*',
      // 'populate[assets][populate]': '*',
    };
    return this.api.get(`/paid-medias`, data).pipe(
      tap((footer) => {
        this._items.next(footer);
      }),
    );
  }
}
