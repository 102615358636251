import { Component, Input } from '@angular/core';
import { WidgetBaseComponent } from '@shared/widgets/widget-base/widget-base.component';

@Component({
  selector: 'card-ads',
  templateUrl: './card-ads.component.html',
  styleUrls: ['./card-ads.component.scss'],
})
export class CardAdsComponent extends WidgetBaseComponent {
  @Input() public position: any;
  @Input() public page: any;
}
